import {EitjeModal} from 'components/common/index'
import utils from '@eitje/web_utils'
import {Routes} from 'components/routing'
import {SideMenu} from 'common/components'
import {useRouteMatch, useLocation} from 'react-router-dom'
import {goBackToForeground} from 'actions/routing'
import {getStringUpToSubstr} from 'helpers'
import {buildRoute} from 'common/components'
// import './styles/side_menu_modal.less'

export const NewSideMenuModal = ({routes, name, children, className = '', sideMenuProps = {}, ...rest}) => {
	const match = useRouteMatch()
	const {path} = match

	const loc = useLocation()
	const basePath = getStringUpToSubstr(loc.pathname, name)

	const menuProps = {...sideMenuRoutes, ...sideMenuProps, name}

	const sideMenuRoutes = routes.map(r => buildRoute(r, sideMenuProps, basePath))
	return (
		<>
			<EitjeModal
				className="side-menu-modal"
				name="setup_revenue"
				LeftPanel={() => <SideMenu modal children={children} routes={routes} {...menuProps} path={path} />}
				name={name}
				onCancel={goBackToForeground}
				{...rest}
			>
				<Routes routes={routes} path={path} sideMenuRoutes={sideMenuRoutes} {...sideMenuProps} />
			</EitjeModal>
		</>
	)
}

export default NewSideMenuModal
