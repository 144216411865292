import {where} from '@eitje/easy_api'
import {ContextForm} from '@eitje/form'
import {Input} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {inviteUser} from 'actions/environment'
import {navigateModal} from 'actions/routing'
import {ListPicker} from 'common/components'
import {EitjeModal, FormRow} from 'components/ui'
import {useFormData, useRoleOrgEnvs} from 'hooks'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import {mdT, t} from 'initializers/i18n'
import {isEmail} from 'lib/form/validation'
import {BaseConnection} from 'models'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'
import {CompositeField} from './composite_field'
import './styles/invite_users_page.less'

const UserFields = ({teams, number}) => {
	return (
		<FormRow>
			<Input validate={validateEmail} bordered={false} field={`email-${number}`} />
			<ListPicker
				dropdown
				title="teams"
				field={`team_ids-${number}`}
				items={teams}
				single={teams.length == 1} // for tour
				labelField="naam"
				placeholder={t('select')}
			/>
		</FormRow>
	)
}

const validateEmail = email => {
	if (isEmail(email)) return true
	return 'emailInvalid'
}

const Inner = ({managerEnvs}) => {
	const {environment_id} = useFormData()
	const teams = useSelector(state => where(state, 'teams', {locked: false, environment_id}))
	const users = useSelector(state => inEnv(state, 'users'))

	const bannerText = users.length === 1 ? mdT('userInviteMessageStart') : mdT('userInviteMessage')
	const {orgEnvs, multiEnvOrg} = useShared()

	return (
		<EitjeModal
			form
			name="invite-users-page"
			title={t('addNewUserToEnv')}
			className="eitje-form-2-use-borders"
			okButtonProps={{disabled: !environment_id}}
		>
			<div className="modal-banner">
				<h5>{bannerText}</h5>
			</div>

			{multiEnvOrg && (
				<FormRow>
					<ListPicker
						dropdown
						single
						field="environment_id"
						placeholder={t('pickEnv')}
						label={t('whichLocation')}
						disabled={environment_id}
						labelField="naam"
						items={managerEnvs}
					/>
				</FormRow>
			)}

			{environment_id && (
				<CompositeField fields={['email', 'team_ids']} compositeName="users" autoAdd initialAmount={3} className="invite-form">
					<UserFields teams={teams} />
				</CompositeField>
			)}
		</EitjeModal>
	)
}

const InviteModal = props => {
	const managerEnvs = useRoleOrgEnvs('manager')
	const conns = BaseConnection.where({hr: true})
	const defId = managerEnvs.length === 1 ? managerEnvs[0].id : null

	const handleSubmit = async data => {
		const users = data.users.filter(u => utils.exists(u.email))
		return inviteUser(users, data.environment_id)
	}

	const afterSubmit = (res, data) => {
		const conn = conns.find(c => c.environment_ids.includes(data.environment_id))
		if (conn?.sanitized_type == 'fooks') {
			return navigateModal(conn.exportUsersUrl(), {keepBackground: true})
		}
		history.goBack()
	}

	return (
		<ContextForm
			initialValues={{environment_id: defId}}
			onSubmit={handleSubmit}
			afterSubmit={afterSubmit}
			transNamespace="invite_users"
			submitInitialValues
			allowEmpty
		>
			<Inner managerEnvs={managerEnvs} {...props} />
		</ContextForm>
	)
}

export default InviteModal
