import {API} from '@eitje/easy_api'
import {LegacyInput as _Input, LegacyDatePicker as DatePicker, LegacySwitch as Switch} from '@eitje/form-fields-web'
import {FormRow} from 'components/form'
import EmoInput from 'components/form/emo_input'
import {InputNumber} from 'components/form/input_number'
import {useEnvIntegration} from 'cores/integrations/index'
import useSingleContracts from 'hooks/use_contract'
import useShared from 'hooks/use_shared'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import ImagePicker from 'lib/form/fields/image_picker'
import {BaseConnection} from 'models'
import {useSelector} from 'react-redux'
import {hrIntegrationSelector} from 'selectors/new_settings'

const submitUser = (data, extIds, names, orgId) => {
	const externalIds = {}
	let extraParams = {}

	if (_.has(data, 'external_id')) {
		externalIds['external_id'] = data['external_id']
	}

	if (Object.keys(externalIds).length > 0) {
		extraParams = {settings: {external_ids: externalIds}}
	}

	if (data['nmbrs_id']) {
		extraParams['settings'] = extraParams['settings'] || {}
		extraParams['settings']['nmbrs'] = {employee_id: data['nmbrs_id']}
	}

	data['external_ids_attributes'] = []
	names.forEach(name => {
		const val = data[name]
		if (val != '' && !val) return

		const oldExtId = extIds.find(e => e.name == name)
		const obj = {id: oldExtId?.id, external_id: val, _destroy: val == '', name, organisation_id: orgId}
		data['external_ids_attributes'].push(obj)
	})

	return API.update('users', data, {extraParams})
}

// Used on the User Preference Modal
const Input = props => <_Input bordered={false} {...props} />

const UserForm = ({user}) => {
	const hrIntegration = useSelector(hrIntegrationSelector)
	const modernInteg = useEnvIntegration({hr: true})
	const {hasManagerRole, hasContractRole, hasAdminRole, orgId} = useShared()
	const contracts = useSingleContracts(user.id)
	let {date_started, external_ids = []} = user
	const firstContract = contracts[contracts.length - 1]
	date_started = date_started || firstContract?.start_date

	let externalIdInitial = {}
	external_ids.forEach(id => {
		externalIdInitial[id.name] = id.external_id
	})

	const hrConnections = BaseConnection.where({hr: true})
	const piggy = BaseConnection.find({sanitized_type: 'piggy'})
	const hrTypes = [...hrConnections._map('sanitized_type'), piggy.id && 'piggy'].filter(Boolean)

	return (
		<Form
			debounceTime={250}
			fieldProps={{submitStrategy: 'blur'}}
			initialValues={{
				...user,
				...externalIdInitial,
				voornaam: user.voornaam == user.email ? null : user.voornaam,
				remote_avatar_url: user.avatar,
				date_started,
			}}
			onSubmit={data => submitUser(data, external_ids, hrTypes, orgId)}
		>
			<FormRow fieldWrapper>
				<ImagePicker submitStrategy="change" field="remote_avatar_url" />
			</FormRow>

			<FormRow fieldWrapper>
				<Input required field="voornaam" />
				<Input required field="achternaam" />
			</FormRow>

			<FormRow fieldWrapper className="element7525">
				<Input required submitStrategy="inlineButton" field="email" />
			</FormRow>

			<FormRow fieldWrapper>
				<Input field="telefoonnummer" />
				<DatePicker bordered={false} defaultPickerValue="2000-01-01" futureDisabled submitStrategy="change" field="verjaardag" />
			</FormRow>

			<FormRow fieldWrapper>
				<Input name="phone" label="ICE" field="ice" />
				{hasAdminRole && <Input field="BSN" />}
			</FormRow>

			<FormRow fieldWrapper>
				{hasContractRole && <InputNumber field="cost_per_hour" label={t('common.hourly_labour_cost')} />}
				<Switch label="taxReduction" extraLabel={<p>{t('applyTax')}</p>} submitStrategy="change" field="lkorting" />
			</FormRow>

			<FormRow fieldWrapper>
				<Input field="postcode" />
				<Input field="huisnummer" />
			</FormRow>

			<FormRow fieldWrapper>
				<Input field="stad" />
				<Input field="straat" />
			</FormRow>

			<FormRow fieldWrapper>
				<DatePicker bordered={false} submitStrategy="change" field="date_started" />
			</FormRow>

			<div id="user_note" fieldWrapper>
				{hasManagerRole && (
					<EmoInput
						bordered={false}
						submitStrategy="inlineButton"
						extraEmoStyle={{right: 0, bottom: 0}}
						autoSize={{minRows: 4, maxRows: 6}}
						textarea
						field="note"
					/>
				)}
			</div>

			<FormRow fieldWrapper>
				{hrIntegration == 'visma' && <Input numOnly field="visma_id" />}
				{hrIntegration == 'nmbrs' && modernInteg?.sanitized_type != 'nmbrs' && <Input field="nmbrs_id" />}
				<Input field="external_id" />

				{hrTypes.map(id => (
					<Input field={id} label={`${id} ID`} />
				))}
			</FormRow>

			<FormRow fieldWrapper>
				<Input label="bankAccount" field="rek_nr" />
				<Switch field="prevent_auto_check_out" submitStrategy="throttledChange" />
				{/* strangely enough, in Safari blur doesn't work for switches, so we moved to throttledChange */}
			</FormRow>
		</Form>
	)
}

export default UserForm
